<template>
    <div
        ref="photosContainer"
        tabindex="0"
        v-click-outside="deselectAllSlides"
        @keydown="handleSlideKeydown"
        class="manage-photos-container"
        @wheel.passive="handleWheel"
    >
        <ActionBar
            ref="actionBar"
            :dropTarget="uploadDropTarget"
            :maxFilesLeft="maxFilesLeft"
            :isExpanded="isExpanded"
            :selectedSlides="selectedSlides"
            :disableMoveBack="disableMoveBack"
            :disableMoveForward="disableMoveForward"
            @add-text-slide="addNewTextSlide"
            @temp-file-change="tempFiles => handleTempFilesChange(tempFiles)"
            @files-added="onFileAdded"
            @move-slide-backward="moveSlideBackward"
            @move-slide-forward="moveSlideForward"
            @edit-active-slide="editActiveSlide"
            @rotate-selected-slides="rotateSelectedSlides"
            @open-delete-modal="openDeleteModal"
            @successful-uploads="uploads => handleSuccessfulUploads(uploads)"
        ></ActionBar>

        <div ref="sortableSlides" :class="['slides-container', { expanded: isExpanded }]">
            <div
                v-if="showLoadMore"
                v-intersect="(entries, observer, isIntersecting) => handleSlidesStartIntersect(isIntersecting)"
                @click="() => slidesNextPage()"
                class="ghost-slide ignore-draggable"
            >
                <div class="d-flex flex-column align-center">
                    <v-progress-circular class="mb-4" size="50" color="orange" indeterminate></v-progress-circular>
                    <span class="text-caption">Loading slides..</span>
                </div>
            </div>
            <div
                v-for="(slide, index) in reversedSlides"
                :key="`${slide.id}-${index}`"
                :id="`slide-${slide.id}`"
                @click.prevent="handleSlideClick($event, slide)"
                @dblclick.prevent="previewSlide($event, index)"
                :class="['slide', { 'ignore-draggable': slide.loading }]"
            >
                <div
                    class="slide-order"
                    :style="{
                        zIndex: 1000,
                    }"
                >
                    {{ getSlideDbIndex(index) }}
                    <span v-if="slide.duration" class="duration">
                        <font-awesome-icon icon="fa-regular fa-video" />
                        {{ slide.duration | formatTimeStamp }}
                    </span>
                </div>

                <CustomTooltip class="slide-preview" :tooltipProps="{ top: true }">
                    <template #activator>
                        <div class="slide-preview-inner">
                            <button @click.capture.prevent="previewSlide($event, index)">
                                <div>
                                    <font-awesome-icon icon="fa-regular fa-eye" />
                                </div>
                            </button>
                        </div>
                    </template>

                    <template #content>
                        <span>Preview</span>
                    </template>
                </CustomTooltip>

                <ImageThumbnail
                    smart-crop
                    :key="slide.url"
                    :src="slide.url"
                    :w="400"
                    :h="400"
                    :type="slide.mediaType"
                    :alt="'Slide ' + getSlideDbIndex(index)"
                    class="slide-image"
                    :style="{
                        transform: 'rotate(' + slide.rotation + 'deg)',
                    }"
                />
                <div
                    v-if="selectedSlides.some(x => x.id === slide.id)"
                    class="selected-indicator"
                    :style="{ position: 'absolute', top: '8px', right: '8px', zIndex: 2 }"
                >
                    <svg
                        width="13.48"
                        height="10.5"
                        viewBox="0 0 13.48 10.5"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path d="M1 4.33333L5.08021 8.41208L12.48 1" stroke="white" stroke-width="2" />
                    </svg>
                </div>

                <v-progress-circular
                    v-if="slide.loading"
                    style="position: absolute; top: 0; bottom: 0; left: 0; right: 0; margin: auto"
                    indeterminate
                    color="#e75a07"
                ></v-progress-circular>
            </div>

            <!-- These are the pending uploads -->
            <template v-for="(pendingSlide, index) in pendingUploads" v-if="pendingUploads.length">
                <div class="pending-slide ignore-draggable" :key="pendingSlide.id">
                    <div
                        class="slide-order"
                        :style="{
                            zIndex: 1000,
                        }"
                    >
                        {{ totalSlides + (index + 1) }}
                    </div>
                    <ImagePreview
                        class="slide-image"
                        :media="pendingSlide"
                        :alt="'Slide ' + totalSlides + (index + 1)"
                    />
                    <div class="progress-indicator">
                        <div class="progress-slider" :style="{ width: `${pendingSlide.progress.percentage}%` }"></div>
                    </div>
                </div>
            </template>
            <div v-if="totalSlides == 0" class="ghost-slide ignore-draggable" @click="triggerSlideUpload">
                <div class="ghost-icon-container">
                    <svg
                        class="ghost-icon"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M3 17C3 16.4477 3.44772 16 4 16H16C16.5523 16 17 16.4477 17 17C17 17.5523 16.5523 18 16 18H4C3.44772 18 3 17.5523 3 17ZM6.29289 6.70711C5.90237 6.31658 5.90237 5.68342 6.29289 5.29289L9.29289 2.29289C9.48043 2.10536 9.73478 2 10 2C10.2652 2 10.5196 2.10536 10.7071 2.29289L13.7071 5.29289C14.0976 5.68342 14.0976 6.31658 13.7071 6.70711C13.3166 7.09763 12.6834 7.09763 12.2929 6.70711L11 5.41421L11 13C11 13.5523 10.5523 14 10 14C9.44771 14 9 13.5523 9 13L9 5.41421L7.70711 6.70711C7.31658 7.09763 6.68342 7.09763 6.29289 6.70711Z"
                            fill="#F97316"
                        />
                    </svg>
                </div>
            </div>

            <div v-for="i in mounted ? 0 : 10" class="slide ignore-draggable">
                <v-skeleton-loader height="100%" type="image"></v-skeleton-loader>
            </div>
        </div>

        <v-dialog v-model="showDeleteModal" max-width="500px">
            <v-card class="p-3">
                <v-card-title>
                    <div>
                        <span style="background-color: #fee2e1; border-radius: 10rem; padding: 6px">
                            <font-awesome-icon
                                style="color: #ff5254"
                                icon="fa-regular fa-triangle-exclamation"
                            ></font-awesome-icon>
                        </span>

                        <span>
                            Confirm deletion of
                            {{ selectedSlides.length === 1 ? 'selected media' : `${selectedSlides.length} media` }}?
                        </span>
                    </div>
                </v-card-title>
                <v-card-text>
                    <div>
                        Are you sure you want to delete
                        {{
                            selectedSlides.length === 1
                                ? 'the selected media'
                                : `these ${selectedSlides.length} slides`
                        }}? This action cannot be undone.
                    </div>
                </v-card-text>
                <v-card-actions class="d-flex justify-end">
                    <v-btn outlined @click="closeDeleteModal">Cancel</v-btn>
                    <v-btn depressed color="error" class="btn btn-deactivate" @click="confirmDelete">Confirm</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- The Imgly CreativeEditor component wrapped in a modal -->
        <v-dialog v-model="showAddTextSlideModal">
            <v-card v-if="editingPhoto" style="height: 85vh;">
                <div class="text-right pa-2">
                    <v-btn @click="showAddTextSlideModal = false" depressed>Close</v-btn>
                </div>
                <div v-if="editingPhoto.error" class="image-error-div">
                    <div v-if="editingPhoto.error.heicError">
                        <p>Unsupported image type (HEIC) for image editor, image queued for conversion</p>
                    </div>
                    <div class="text-center" v-else>
                        <p>An error occured while loading the image.</p>
                        <v-btn @click="deleteSelectedSlides" depressed color="error">Delete Image</v-btn>
                    </div>
                </div>

                <div v-else class="image-error-div">
                    <v-progress-circular size="50" indeterminate color="primary"></v-progress-circular>
                </div>
                <div>
                    <PhotoEditor ref="photoEditor" :photo="editingPhoto.photo" :visible="editingPhoto.visible" :theme="imglyConfig.theme"
                        :layout="imglyConfig.mobile ? 'basic' : 'advanced'" :image-path="editingPhoto.path" :blank-image="editingPhoto.blankImage"
                        :token="token" :eventId="$props.eventId" @close="() => showAddTextSlideModal = false"
                        @export-success="onEditorSave"
                        @created-export-success="onEditorSave"
                        @delete="deletePhotoById" 
                        @revert-original="revertPhoto(editingPhoto.photo)"
                        @image-error="() => editingPhoto.error = {}" />
                </div>
            </v-card>
        </v-dialog>
        <!-- Slide preview modal -->
        <v-dialog @keydown="handlePreviewModalKeydown" v-model="showSlidePreviewModal" :overlay-opacity="0.85">
            <div class="preview-modal">
                <v-btn
                    class="carousel-btn modal-close-btn"
                    color="white"
                    depressed
                    @click="() => (showSlidePreviewModal = false)"
                    >X</v-btn
                >
                <div class="slide-toolbar p-2 text-center">
                    <div class="edit-button">
                        <v-btn
                            class="carousel-btn"
                            color="white"
                            depressed
                            v-if="reversedSlides[previewingIndex]"
                            :disabled="reversedSlides[previewingIndex].mediaType === 1"
                            @click="actionFromPreviewImage($event, 'edit')"
                        >
                            <svg
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M16.8898 3.11019L17.5969 2.40309V2.40309L16.8898 3.11019ZM5.41673 17.5296V18.5296C5.68194 18.5296 5.9363 18.4242 6.12383 18.2367L5.41673 17.5296ZM2.50006 17.5296H1.50006C1.50006 18.0819 1.94778 18.5296 2.50006 18.5296V17.5296ZM2.50006 14.5537L1.79295 13.8466C1.60542 14.0341 1.50006 14.2885 1.50006 14.5537H2.50006ZM14.6507 3.8173C15.0737 3.39423 15.7596 3.39423 16.1827 3.8173L17.5969 2.40309C16.3928 1.19897 14.4406 1.19897 13.2364 2.40309L14.6507 3.8173ZM16.1827 3.8173C16.6058 4.24037 16.6058 4.9263 16.1827 5.34937L17.5969 6.76358C18.801 5.55946 18.801 3.6072 17.5969 2.40309L16.1827 3.8173ZM16.1827 5.34937L4.70962 16.8225L6.12383 18.2367L17.5969 6.76358L16.1827 5.34937ZM5.41673 16.5296H2.50006V18.5296H5.41673V16.5296ZM13.2364 2.40309L1.79295 13.8466L3.20717 15.2608L14.6507 3.8173L13.2364 2.40309ZM1.50006 14.5537V17.5296H3.50006V14.5537H1.50006ZM11.9864 5.0673L14.9327 8.01358L16.3469 6.59937L13.4007 3.65309L11.9864 5.0673Z"
                                    fill="#6B7280"
                                />
                            </svg>
                        </v-btn>
                    </div>
                    <div class="rotate-button">
                        <v-btn
                            class="carousel-btn"
                            color="white"
                            depressed
                            @click="actionFromPreviewImage($event, 'rotate')"
                        >
                            <svg
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M9.96,8.49c-.15-.15-.15-.41,0-.56l1.12-1.12c.15-.15.41-.15.56,0l2.3,2.3c.28-1.77-.27-3.65-1.64-5.01-2.27-2.27-5.95-2.27-8.22,0s-2.27,5.95,0,8.22c1.04,1.04,2.39,1.61,3.75,1.69.21.01.37.18.37.39v1.59c0,.22-.19.41-.42.39-1.96-.1-3.89-.89-5.39-2.39-3.2-3.2-3.2-8.37-.01-11.58,3.19-3.2,8.34-3.23,11.56-.05,1.74,1.71,2.55,4.01,2.43,6.27l1.82-1.82c.15-.15.41-.15.56,0l1.12,1.12c.15.15.15.41,0,.56l-4.68,4.68c-.15.15-.41.15-.56,0l-4.68-4.68h0Z"
                                    fill="#6B7280"
                                ></path>
                            </svg>
                        </v-btn>
                    </div>
                    <div class="delete-button">
                        <v-btn
                            class="carousel-btn"
                            color="white"
                            depressed
                            @click="actionFromPreviewImage($event, 'trash')"
                        >
                            <svg
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M15.8333 5.83333L15.1105 15.9521C15.0482 16.8243 14.3225 17.5 13.4481 17.5H6.55184C5.67745 17.5 4.95171 16.8243 4.88941 15.9521L4.16665 5.83333M8.33331 9.16667V14.1667M11.6666 9.16667V14.1667M12.5 5.83333V3.33333C12.5 2.8731 12.1269 2.5 11.6666 2.5H8.33331C7.87308 2.5 7.49998 2.8731 7.49998 3.33333V5.83333M3.33331 5.83333H16.6666"
                                    stroke="#EF4444"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                ></path>
                            </svg>
                        </v-btn>
                    </div>
                </div>
                <v-carousel v-model="previewingIndex" continuous hide-delimiters ref="previewCarousel">
                    <template v-slot:prev="{ on, attrs }">
                        <v-btn class="carousel-btn" color="white" depressed v-bind="attrs" v-on="on">&lt;</v-btn>
                    </template>
                    <template v-slot:next="{ on, attrs }">
                        <v-btn class="carousel-btn" color="white" depressed v-on="on">&gt;</v-btn>
                    </template>

                    <v-carousel-item v-for="(slide, index) in reversedSlides" :key="`${slide.id}-${index}`" eager>
                        <v-sheet elevation="0" rounded height="100%">
                            <v-row class="m-0 fill-height" align="center" justify="center">
                                <div class="carousel-content" :ref="`slidePreview_${index}`">
                                    <div class="slide-order">{{ getSlideDbIndex(index) }}</div>
                                    <video v-if="slide.mediaType === 1" controls>
                                        <source :src="slide.url" type="video/webm" />
                                    </video>
                                    <ImageThumbnail
                                        v-else
                                        :key="slide.url"
                                        :src="slide.url"
                                        :w="null"
                                        :h="800"
                                        :type="slide.mediaType"
                                        :alt="'Slide ' + getSlideDbIndex(index)"
                                        class="slide-image"
                                    />
                                </div>
                            </v-row>
                        </v-sheet>
                    </v-carousel-item>
                </v-carousel>
            </div>
        </v-dialog>
        <!-- </div> -->
    </div>
</template>

<script>
import sortableHelper from '@/utilities/sortableHelper';
import multiSelectUtils from '../../utilities/multiSelectUtils';
import draggable from 'vuedraggable';
import modal from './WarningModal.vue';
import initApiServices from '@/services/ApiInitializer';
import { orderBy, debounce, each } from 'lodash';

import ImagePreview from '../Media/ImagePreview.vue';
import ImageThumbnail from '../Media/ImageThumbnail.vue';
import { mapActions } from 'vuex';
import ActionBar from './Slides/ActionBar.vue';

import CustomTooltip from '@/components/ui/CustomTooltip.vue';

import moment from 'moment';
export default {
    name: 'ManagePhotos',
    components: {
        draggable,
        modal,
        ImagePreview,
        ImageThumbnail,
        ActionBar,
        CustomTooltip,
        PhotoEditor: () => import('@/components/ui/PhotoEditor')
    },
    props: {
        eventId: {
            type: Number,
            required: true,
        },
        tributeVideo: {
            type: Object,
            required: true,
        },
    },
    async mounted() {
        const response = await this.$auth.getIdTokenClaims();
        this.token = response.__raw;
        this.apiService = initApiServices(this.token);

        if (this.eventId) {
            let data = {
                reversed: true,
                pageNumber: this.pageNumber,
                pageSize: this.pageSize,
            };

            this.replaceCurrentPhotos(data);
        }
        this.initSortable();
    },
    data() {
        return {
            apiService: null,
            token: null,
            maxFiles: 400,
            pendingUploads: [],
            reversedSlides: [],
            selectedSlides: [],
            totalSlides: 0,
            isDragging: false,
            dragIndex: null,
            sortableInstance: null,
            showAddTextSlideModal: false,
            previewingIndex: 0,
            showSlidePreviewModal: false,
            textSlideBgColor: '#ffffff',
            textSlideContent: '',
            showDeleteModal: false,
            recordedScrollTop: 0,
            recordedScrollLeft: 0,
            pageNumber: 0,
            pageSize: 24,
            mounted: false,
            progress: 0,
            pendingReorder: false,
            pendingDelete: false,
            pendingRotate: false,
            selectRangeStart: null,
            uploadDropTarget: '.manage-photos-container',
            fetchingSlides: false,
            fetchingAllSlides: false,
            imglyConfig: {
                theme: 'light',
                // TODO: Figure out where this should come from
                mobile: false,
                // layout: 'advanced',
                // layout: 'basic',
                license: '{"owner":"Imgly Inc.","version":"2.4"}',
            },
            editingPhoto: null
        };
    },
    computed: {
        maxFilesLeft() {
            // computed difference between how many files max and existing items
            // TODO: included pending uploads while they resolve and remove if they error, etc
            return this.maxFiles - this.reversedSlides.length;
        },
        isExpanded: {
            get() {
                return this.$store.state.tributeEditor.expanded;
            },
        },
        containerHeight: {
            get() {
                return this.$store.state.tributeEditor.workspaceHeight;
            },
        },

        isAnyImageSelected() {
            return this.selectedSlides.length > 0;
        },

        disableMoveBack() {
            if (this.selectedSlides.length !== 1) return true;

            if (this.reversedSlides.length === 0) return true;

            return this.reversedSlides[0].id === this.selectedSlides[0].id;
        },

        disableMoveForward() {
            if (this.selectedSlides.length !== 1) return true;

            if (this.reversedSlides.length === 0) return true;

            return this.reversedSlides[this.reversedSlides.length - 1].id === this.selectedSlides[0].id;
        },
        showLoadMore() {
            return this.reversedSlides.length < this.totalSlides;
        },
    },
    filters: {
        formatTimeStamp(seconds) {
            const duration = moment.duration(seconds, 'seconds');
            const minutes = duration.minutes();
            const secs = duration.seconds();

            const formattedMinutes = String(minutes).padStart(2, '0');
            const formattedSeconds = String(secs).padStart(2, '0');

            return `${formattedMinutes}:${formattedSeconds}`;
        },
    },
    watch: {
        isExpanded(newVal) {
            if (newVal) {
                this.$refs.photosContainer.focus();
            }
        },
        selectedSlides(newVal) {
            const selectedIds = newVal.map(slide => slide.id);
            this.syncFromSelected(selectedIds);
        },
        previewingIndex(newIndex, oldIndex) {
            if (newIndex !== null && oldIndex !== null && newIndex !== oldIndex) {
                const tmpRef = this.$refs[`slidePreview_${oldIndex}`];
                if (tmpRef?.length) {
                    const videoEl = tmpRef[0].querySelector('video');
                    if (videoEl && !videoEl.paused) {
                        console.log('video paused');
                        videoEl.pause();
                    }
                }
            }
        },
    },
    methods: {
        ...mapActions(['showSnackbar', 'block']),
        ...mapActions('tributeEditor', ['toggleExpandedState']),

        initSortable() {
            const container = this.$refs.sortableSlides;

            if (!container) return;

            this.sortableInstance = sortableHelper.initSortable(container, {
                onStart: this.onDragStart,
                onEnd: this.onDragEnd,
                onSelect: this.onSelect,
                onDeselect: this.onDeselect,
            });
        },
        handleWheel(event) {
            if (this.fetchingSlides || event.shiftKey) return;

            // How much a user needs to scroll before it triggers the expanding behavior
            const wheelBuffer = 50;
            // Access event.deltaY to get the scroll direction and amount
            const direction = event.deltaY > 0 ? 'down' : event.deltaY < 0 ? 'up' : '';
            const distance = Math.abs(event.deltaY);
            // console.log('Mousewheel:', { direction, distance, y: event.deltaY, event});
            // slides-container
            const slideContainer = document.querySelector('.slides-container');
            // Implement your custom logic here
            if (distance > wheelBuffer) {
                if (direction === 'down' && !this.isExpanded) {
                    this.toggleExpandedState();
                }

                if (direction === 'up' && this.isExpanded && slideContainer.scrollTop < 5) {
                    // ensure slides-container is at the top before collapsing
                    // console.log(slideContainer);
                    this.toggleExpandedState();
                }
            }
        },
        triggerSlideUpload() {
            if (this.$refs.actionBar?.$refs?.uploaderBtn) {
                this.$refs.actionBar.$refs.uploaderBtn.openFileSelection();
            }
        },
        onSelect(evt) {
            // if (!evt.item) return;
            // const id = this.parseSlideId(evt.item);
            // if (!id) return;
            // if (this.selectedSlides.some(x => x.id == id)) return;
            // let found = this.reversedSlides.find(x => x.id === id);
            // if (!found) return;
            // this.selectedSlides.push(found);
        },
        onDeselect(evt) {
            // if (!evt.item) return;
            // const id = this.parseSlideId(evt.item);
            // if (!id) return;
            // let index = this.selectedSlides.findIndex(x => x.id === id);
            // if (index === -1) return;
            // this.selectedSlides.splice(index, 1);
        },
        onDragStart(evt) {
            if (!evt.item) return;

            const id = this.parseSlideId(evt.item);
            if (!id) return;

            if (this.selectedSlides.some(x => x.id === id)) return;

            const found = this.reversedSlides.find(x => x.id === id);
            if (!found) return;

            this.selectedSlides.push(found);
        },

        async onDragEnd(evt) {
            try {
                const sortedNewIndicies = evt.newIndicies.slice().sort((a, b) => a.index - b.index);
                const slideIds = sortedNewIndicies
                    .map(item => {
                        let parsedInt = parseInt(item.multiDragElement.id.replace('slide-', ''));
                        return Number.isNaN(parsedInt) ? null : parsedInt;
                    })
                    .filter(Number.isInteger);

                const startingIndex = sortedNewIndicies[0].index;

                await this.reorderSlides(slideIds, startingIndex);
            } catch (error) {
                console.log(error, 'multi update error');
            }
        },
        previewSlide(event, index) {
            this.previewingIndex = index;
            this.showSlidePreviewModal = true;
        },
        actionFromPreviewImage(evt, action) {
            this.selectedSlides = [this.reversedSlides[this.previewingIndex]];
            console.log(this.selectedSlides);
            if (!this.selectedSlides.length) {
                console.log('hmm no slides to perform action on');
                return;
            }
            this.$nextTick(() => {
                setTimeout(() => {
                    if (action === 'edit') {
                        this.editActiveSlide();
                        this.showSlidePreviewModal = false;
                    }
                    if (action === 'rotate') {
                        this.rotateSelectedSlides();
                    }
                    if (action === 'trash') {
                        this.showSlidePreviewModal = false;
                        // timeout to allowe for preview modal to clear.
                        // Future improvement: The delete modal should be able to be opened without having to close the preview modal
                        setTimeout(() => {
                            this.openDeleteModal();
                        }, 400);
                    }
                }, 300);
            });
        },
        async reorderSlides(slideIds, startingArrIndex) {
            try {
                if (this.pendingReorder) {
                    this.showSnackbar({ message: 'Please wait for current edit to finish', color: 'error' });
                    this.deselectAll(this.sortableInstance);
                    return;
                }

                this.pendingReorder = true;
                this.deselectAll(this.sortableInstance);
                this.updateLoadingSlides(slideIds);

                const targetOrder = this.getTargetOrder(startingArrIndex);

                const slideMap = new Map(this.reversedSlides.map(slide => [slide.id, slide]));

                const orderedSlides = slideIds.map(id => slideMap.get(id)).filter(Boolean);

                const remainingSlides = this.reversedSlides.filter(slide => !slideIds.includes(slide.id));

                const updatedSlides = [
                    ...remainingSlides.slice(0, startingArrIndex),
                    ...orderedSlides,
                    ...remainingSlides.slice(startingArrIndex),
                ];

                const dedupedSlides = Array.from(new Map(updatedSlides.map(slide => [slide.id, slide])).values());
                this.reversedSlides = dedupedSlides;

                //May need to rethink error handling here,
                // but the response time feels much better for users
                var res = await this.apiService.tributePhoto.multiUpdateOrder(
                    this.tributeVideo.id,
                    targetOrder,
                    slideIds,
                );
            } catch (error) {
                console.log(error, 'multi update error');
                this.showSnackbar({ message: 'Error', color: 'error' });
            } finally {
                this.$emit('refresh-preview');
                setTimeout(() => {
                    this.updateLoadingSlides([]);
                    this.pendingReorder = false;
                }, 1000);
            }
        },
        updateLoadingSlides(slideIds) {
            this.reversedSlides = this.reversedSlides.map(slide => ({
                ...slide,
                loading: slideIds.includes(slide.id),
            }));
        },
        deselectAll() {
            sortableHelper.deselectAll(this.sortableInstance);
            this.selectedSlides = [];
        },

        syncFromSelected(ids) {
            this.$nextTick(() => {
                sortableHelper.syncSelected(this.sortableInstance, ids, '#slide-');
            });
        },
        parseSlideId(element) {
            let parsedInt = parseInt(element.id.replace('slide-', ''));
            return Number.isNaN(parsedInt) ? null : parsedInt;
        },
        getSelectedSlideIds() {
            const selectedSlides = sortableHelper.getSelectedElements(this.sortableInstance);

            return Array.from(selectedSlides).map(this.parseSlideId).filter(Number.isInteger);
        },
        //TODO: need to wire this up, holding for ui design
        async toggleSlideMute(slide) {
            if (slide.mediaType != 1) {
                this.showSnackbar({ message: 'Invalid slide media type selected', color: 'error' });
                return;
            }

            try {
                slide.mute = !slide.mute;
                await this.apiService.tributePhoto.setVideoSlideMute(slide.id, slide.mute);
            } catch (error) {
                console.log(error, 'error toggling slide mute');
            }
        },
        async handleSlideClick(evt, slide) {
            if (evt.target.closest('.slide-preview')) {
                return;
            }
            const action = multiSelectUtils.handleClick(evt, slide, {
                selectedItems: this.selectedSlides,
                allItems: this.reversedSlides,
                selectRangeStart: this.selectRangeStart,
            });

            if (Array.isArray(action.selectedItems) && action.type != 'none') {
                this.selectedSlides = action.selectedItems;
                this.selectRangeStart = action.selectRangeStart;
            }
        },
        deselectAllSlides(event) {
            if (this.showDeleteModal || this.showSlidePreviewModal || this.showAddTextSlideModal) return;
            if (event.target.closest('.v-dialog__content--active')) return;
            this.selectedSlides = [];
            this.selectRangeStart = null;
        },
        handlePreviewModalKeydown(evt) {
            // If modal is open in a modal let the keypresses be handled normally.
            // TODO: this may need revisting later to maybe be a mode toggle, like ON/OFF
            const activeModal = document.querySelector('.v-dialog__content--active');
            const previewModal = document.querySelector('.preview-modal');
            if (activeModal) {
                if (previewModal) {
                    // Preview modal is open
                    const previewCarousel = this.$refs.previewCarousel;
                    switch (evt.key.toLowerCase()) {
                        case 'arrowleft':
                            // go left
                            previewCarousel?.prev();
                            break;
                        case 'arrowright':
                            // go right
                            previewCarousel.next();
                            break;
                    }
                }
                return;
            }
        },
        async handleSlideKeydown(evt) {
            evt.preventDefault();

            const firstSlide = document.querySelector('.slide');

            const action = await multiSelectUtils.handleKeydown(evt, {
                selectedItems: this.selectedSlides,
                allItems: this.reversedSlides,
                selectRangeStart: this.selectRangeStart,
                containerWidth: this.$refs.sortableSlides.offsetWidth,
                itemWidth: firstSlide ? firstSlide.offsetWidth : 0,
                fetchAllItemsCallback: this.fetchAllSlides,
                deleteItemsCallback: this.openDeleteModal,
            });

            if (action && Array.isArray(action.selectedItems) && action.type != 'none') {
                this.selectedSlides = action.selectedItems;
                this.selectRangeStart = action.selectRangeStart;

                const indexToFocus = this.determineFocusIndex(action);
                if (indexToFocus != null) {
                    this.focusImage(indexToFocus);
                }
            }
        },
        determineFocusIndex(action) {
            if (this.selectedSlides.length == 1) {
                return this.reversedSlides.indexOf(this.selectedSlides[0]);
            }

            if (this.selectedSlides.length > 1) {
                const endSelectedIndex = this.reversedSlides.indexOf(
                    this.selectedSlides[this.selectedSlides.length - 1],
                );
                const startSelectedIndex = this.reversedSlides.indexOf(this.selectedSlides[0]);

                return endSelectedIndex === action.selectRangeStart ? startSelectedIndex : endSelectedIndex;
            }

            return null;
        },
        async fetchAllSlides() {
            try {
                if (this.fetchingAllSlides) return;

                this.fetchingAllSlides = true;

                if (this.reversedSlides.length === this.totalSlides) {
                    return this.reversedSlides;
                }

                let data = {
                    reversed: true,
                    pageSize: this.totalSlides,
                };

                const allSlides = await this.replaceCurrentPhotos(data);

                if (allSlides) {
                    return allSlides;
                }

                return null;
            } finally {
                this.fetchingAllSlides = false;
                this.fetchingSlides = false;
            }
        },
        getSlideDbIndex(index) {
            let hiddenSlides = 0;

            if (this.totalSlides > this.reversedSlides.length) {
                hiddenSlides = this.totalSlides - this.reversedSlides.length;
            }

            return index + 1 + hiddenSlides;
        },
        handleSlidesStartIntersect(isIntersecting) {
            if (this.mounted && isIntersecting) {
                this.slidesNextPage();
            }
        },
        recordScrollPosition() {
            const container = this.$el.querySelector('.slides-container');
            if (container) {
                this.recordedScrollTop = container.scrollHeight - container.scrollTop;
                this.recordedScrollLeft = container.scrollWidth - container.scrollLeft;
            }
        },
        restoreScrollPosition() {
            const container = this.$el.querySelector('.slides-container');

            if (container) {
                container.scrollTop = container.scrollHeight - this.recordedScrollTop;
                container.scrollLeft = container.scrollWidth - this.recordedScrollLeft;
            }
        },
        async slidesNextPage() {
            if (this.pendingUploads.length > 0 || this.reversedSlides.length >= this.totalSlides) return;

            console.log('getting next page');
            try {
                this.fetchingSlides = true;
                this.recordScrollPosition();

                this.pageNumber++;

                let data = {
                    reversed: true,
                    pageNumber: this.pageNumber,
                    pageSize: this.pageSize,
                };

                console.log(data, 'fetch data');
                var resp = await this.apiService.tributePhoto.getPhotos(this.eventId, data);
                console.log(resp, 'photo fetch respresp');

                if (resp.data.photos) {
                    let nextPageSlides = resp.data.photos.slice().reverse();

                    this.reversedSlides = [
                        ...nextPageSlides.map(slide => ({ ...slide, rotation: 0, loading: false })),
                        ...this.reversedSlides,
                    ];

                    this.$nextTick(() => {
                        this.restoreScrollPosition();
                    });
                }

                if (resp.data.total) {
                    this.totalSlides = resp.data.total;
                }
            } catch (error) {
                console.log(error, 'error fetching slides');
            } finally {
                this.fetchingSlides = false;
            }
        },

        // This method should not alter current pageNumber or pageSize to
        // avoid interfering with infinite scroll pagination
        //Just reload and replace the current loaded images
        async replaceCurrentPhotos(params) {
            var resp = await this.apiService.tributePhoto.getPhotos(this.eventId, params);

            if (resp.data) {
                this.reversedSlides = resp.data.photos
                    .slice()
                    .reverse()
                    .map(slide => {
                        return { ...slide, rotation: 0, loading: false };
                    });
                this.$nextTick(() => {
                    this.mounted = true;
                    this.scrollGalleryToEnd();
                });
            }

            if (resp.data.total >= 0) {
                this.totalSlides = resp.data.total;
            }

            return this.reversedSlides;
        },
        async deleteSelectedSlides() {
            try {
                this.pendingDelete = true;
                this.block(true);
                const selectedIds = this.getSelectedSlideIds();

                if (selectedIds.length === 0) throw new Error('No valid slides selected');

                var resp = await this.apiService.tributePhoto.deletePhotoBatch(selectedIds);

                console.log(resp, 'delete photos resp');
                this.totalSlides -= selectedIds.length;
                this.reversedSlides = this.reversedSlides.filter(x => !selectedIds.includes(x.id));
                // update the tributeVideoStore
                this.$store.dispatch(
                    'tributeVideo/updateTributeVideoSelectedPhotos',
                    structuredClone(this.reversedSlides),
                );
                this.$emit('refresh-preview');
            } catch (error) {
                console.log(error, 'error deleting slide');
            } finally {
                this.pendingDelete = false;
                this.block(false);
            }
        },
        async revertPhoto(photo) {
            this.closeAddTextSlideModal();
            if (photo.originalPhoto == null) {
                this.showSnackbar({ message: 'No photo to revert to' });
                return;
            }

            this.apiService.tributePhoto.revertPhotoToOriginal(photo.id)
            .then(resp => {
                this.reversedSlides[this.editingPhoto.slideIndex] = resp.data;
                this.editingPhoto = null;
                this.showSnackbar({ message: 'Original photo restored' });
            });
        },
        deletePhotoById() {

            this.closeAddTextSlideModal();
            this.$nextTick(async () => {
                const selectedId = this.editingPhoto.photo.id;
                const resp = await this.apiService.tributePhoto.deletePhoto(selectedId);
                this.reversedSlides = this.reversedSlides.filter(x => selectedId !== x.id);
                // update the tributeVideoStore
                this.$store.dispatch(
                    'tributeVideo/updateTributeVideoSelectedPhotos',
                    structuredClone(this.reversedSlides),
                );
                this.$emit('refresh-preview');
                this.editingPhoto = null;
                this.showSnackbar({ message: 'Media deleted successfully' });
            });
            
        },
        scrollGalleryToEnd() {
            const container = this.$el.querySelector('.slides-container');
            if (container) {
                container.scrollTo({
                    top: container.scrollHeight,
                    left: container.scrollWidth,
                    behavior: 'instant',
                });
            }
        },
        moveSlideForward() {
            if (this.selectedSlides.length !== 1) {
                this.showSnackbar({ message: 'Please select 1  slide to move', color: 'error' });
                return;
            }

            const slide = this.selectedSlides[0];
            const index = this.reversedSlides.findIndex(x => x.id === slide.id);

            if (index === -1) {
                this.showSnackbar({ message: 'Slide not found', color: 'error' });
                return;
            }

            if (index + 1 >= this.totalSlides) {
                return;
            }

            this.reorderSlides([slide.id], index + 1);
        },
        moveSlideBackward() {
            if (this.selectedSlides.length !== 1) {
                this.showSnackbar({ message: 'Please select 1  slide to move', color: 'error' });
                return;
            }

            const slide = this.selectedSlides[0];
            const index = this.reversedSlides.findIndex(x => x.id === slide.id);

            if (index === -1) {
                this.showSnackbar({ message: 'Slide not found', color: 'error' });
                return;
            }

            if (index <= 0) {
                return;
            }

            this.reorderSlides([slide.id], index - 1);
        },
        //Converts current "page" slide index to target db order
        getTargetOrder(arrIndex) {
            const invertedIndex = this.reversedSlides.length - arrIndex;
            return this.totalSlides - invertedIndex;
        },
        async editActiveSlide() {
            if (this.selectedSlides.length > 1) {
                this.showSnackbar({ message: 'Please select 1 slide to edit', color: 'error' });
                return;
            }

            if (this.selectedSlides.length === 0) {
                this.showSnackbar({ message: 'No slide selected', color: 'error' });
                return;
            }

            const slide = this.selectedSlides[0];
            const selectedSlideIndex = this.reversedSlides.findIndex(x => x.id === slide.id);
            this.openAddTextSlideModal();
            // TODO: this works for now, but there's a chance that it might need a longer delay once saving the tribute is implemented
            // before attempting to load an existing scene since it sort of assumes the editor is already initialized
            this.$nextTick(async () => {
                this.editingPhoto = {
                    photo: slide,
                    slideIndex: selectedSlideIndex,
                    path: slide.url,
                    blankImage: false,
                    visible: false
                }
                // Trigger photoEditor's "visible" watcher
                setTimeout(() => {
                    this.editingPhoto.visible = true;
                });
            });
        },
        openDeleteModal() {
            if (this.isAnyImageSelected) {
                this.showDeleteModal = true;
            }
        },
        triggerPhotoUpload() {
            this.$refs.fileInput.click();
        },
        createProgressHandler(file) {
            return progressEvent => {
                const currentBytes = this.uploadedBytes + progressEvent.loaded;
                const percent = Math.ceil((currentBytes / this.totalBytes) * 100);

                this.progress = percent;
            };
        },
        async uploadTextSlide(eventId, file) {
            try {
                var remainingAllowed = await this.apiService.tributePhoto.getRemainingAllowedUploads(eventId);

                if (remainingAllowed.remaining <= 0) {
                    throw new Error(`Cannot exceed remaind allowed uploads: ${remainingAllowed.remaining}`);
                }

                let { data: SAS_INFO } = await await this.apiService.tributePhoto.getUploadUrl(eventId, file);

                // TODO: add cancel token
                await this.apiService.blob.upload(SAS_INFO.sas, file, {
                    onUploadProgress: this.createProgressHandler(file),
                });

                let tributePages = ['TributeUploadPage', 'TributeFamilyPage'];

                let data = [
                    {
                        duration: 0,
                        mediaType: 2,
                        name: file.name,
                        url: SAS_INFO.sas.split('?sv=')[0],
                        uniqueName: SAS_INFO.fileName,
                        uploadSource: tributePages.includes(this.$route.name) ? 2 : 0,
                        uploadUserName: tributePages.includes(this.$route.name) ? 'Unknown' : this.$auth.user.name,
                        uploadUserRelationship: tributePages.includes(this.$route.name) ? 'Unknown' : 'Funeral Staff',
                    },
                ];

                var resp = await this.apiService.tributePhoto.createPhotoBatch(eventId, data, false);
                console.log('Updating lastSyncedChange');
                this.$store.dispatch('tributeVideo/updateTributeVideo', {
                    // Set lastSyncedChange, in case we need to delay render
                    lastSyncedChange: moment().toISOString(),
                });
                this.scrollGalleryToEnd();
                this.$emit('refresh-preview');

                if (resp.data.length > 0) {
                    return resp.data[0];
                }

                return null;
            } catch (error) {
                console.log('Error uploading text slide', error);
            }
        },
        async fetchSceneData(sceneDataURL) {
            try {
                const resp = await this.axios.get(sceneDataURL);

                if (resp.data.scene) {
                    return resp.data.scene;
                }

                return null;
            } catch (error) {
                console.log('Error fetching scene data', error);
            }
        },
        generateJsonBlob(jsonData) {
            const jsonString = JSON.stringify(jsonData);

            const jsonBlob = new Blob([jsonString], { type: 'application/json' });

            return jsonBlob;
        },
        async updateSlideScene(slide, scene) {
            try {
                const resp = await this.apiService.tributePhoto.getSlideSceneUploadUrl(slide.id);
                const { sas, fileName } = resp.data;

                const jsonData = {
                    slideId: slide.id,
                    lastModified: new Date().toISOString(),
                    scene: scene,
                };

                var sceneBlob = this.generateJsonBlob(jsonData);

                await this.apiService.blob.upload(sas, sceneBlob);
                const updatedSlideResp = await this.apiService.tributePhoto.updateSlideScene(slide.id, fileName);
                console.log('Updating lastSyncedChange');
                this.$store.dispatch('tributeVideo/updateTributeVideo', {
                    // Set lastSyncedChange, in case we need to delay render
                    lastSyncedChange: moment().toISOString(),
                });
                return updatedSlideResp.data;
            } catch (error) {
                console.log('error uploading scene json', error);
            }
        },
        async onEditorSave(editorResults) {
            // const { metadata } = editorResults;
            this.closeAddTextSlideModal();
            // Are we editing an existing slide or is this a new one?
            const isEditing = this.editingPhoto?.slideIndex >= 0;
            const index = isEditing ? this.editingPhoto.slideIndex : this.reversedSlides.length - 1;
            const fileName = isEditing ? this.reversedSlides[index].name : 'Title Slide.png';

            if (isEditing) {
                slide = this.reversedSlides[index] = editorResults;
            } else {
                // New Slide being added
                this.reversedSlides.push(editorResults);
                this.focusImage(this.reversedSlides.length - 1);
            }
            this.editingPhoto = null;
        },
        addNewTextSlide() {
            // When adding a new text slide, first clear out any existing selected image
            if (this.selectedSlides.length > 0) {
                this.deselectAll(this.sortableInstance);
            }

            // Then open up the editor modal
            this.openAddTextSlideModal();
            this.editingPhoto = {
                blankImage: true,
                path: '',
                slideIndex: null,
                visible: false
            }
            setTimeout(() => {
                this.editingPhoto.visible = true;
            });
        },
        async openAddTextSlideModal() {
            this.showAddTextSlideModal = true;
            await this.$nextTick();
        },
        closeAddTextSlideModal() {
            this.showAddTextSlideModal = false;
        },
        handleTempFilesChange(tempFiles) {
            if (tempFiles.length === 0) return;

            this.pendingUploads = tempFiles;
        },
        handleSuccessfulUploads(newSlides) {
            //Handle batched upload responses
            const newSlideIds = new Set(newSlides.map(slide => slide.uppyId));

            for (let i = this.pendingUploads.length - 1; i >= 0; i--) {
                if (newSlideIds.has(this.pendingUploads[i].id)) {
                    this.pendingUploads.splice(i, 1);
                }
            }

            if (this.tributeVideo.mainPhotoId > 0) {
                this.reversedSlides.push(
                    ...newSlides.map(slide => ({
                        ...slide.dbData,
                        rotation: 0,
                        loading: false,
                    })),
                );

                this.$store.dispatch(
                    'tributeVideo/updateTributeVideoSelectedPhotos',
                    structuredClone(this.reversedSlides),
                );

                this.$store.dispatch('tributeVideo/updateTributeVideo', {
                    // Set lastSyncedChange, in case we need to delay render
                    lastSyncedChange: moment().toISOString(),
                });

                this.totalSlides += newSlides.length;
            }
        },
        onFileAdded: debounce(function () {
            this.$store.dispatch('tributeVideo/updateTributeVideo', {
                // Set lastSyncedChange, in case we need to delay render
                uploadingPhotos: true,
            });
            this.scrollGalleryToEnd();
            // Listen for files being added then trigger the upload automatically
            this.$refs.actionBar.initUpload(this.$props.eventId).then(newSlides => {
                // all uploads should be finished here, so set percentage to 100
                each(this.pendingUploads, up => {
                    if (up.progress.percentage < 100) {
                        up.progress.percentage = 100;
                    }
                });

                this.pendingUploads = [];
                this.deselectAll(this.sortableInstance);

                this.$store.dispatch('tributeVideo/updateTributeVideo', {
                    // Set lastSyncedChange, in case we need to delay render
                    uploadingPhotos: false,
                });
                //Refresh from webhook after ingest pipeline finishes processing
            });
        }, 300),
        async rotateSelectedSlides() {
            try {
                let selectedIds = this.getSelectedSlideIds();
                let selectedSlides = this.reversedSlides.filter(x => selectedIds.includes(x.id));

                selectedSlides.forEach(slide => {
                    slide.rotation = (slide.rotation + 90) % 360;
                });

                this.debouncedMultiSlideRotate(selectedSlides);
            } catch (error) {
                console.log(error, 'error rotating selected images');
            }
        },
        debouncedMultiSlideRotate: debounce(async function (slides) {
            this.pendingRotate = true;

            this.deselectAll(this.sortableInstance);
            slides.forEach(slide => {
                slide.loading = true;
            });
            const rotatePromises = slides.map(slide => this.generateRotatedImage(slide));
            await Promise.allSettled(rotatePromises);

            setTimeout(() => {
                this.pendingRotate = false;
                this.$emit('refresh-preview');
            }, 1000);
        }, 500),
        generateRotatedImage(slide) {
            return new Promise(async (resolve, reject) => {
                try {
                    const image = new Image();
                    image.crossOrigin = 'anonymous';
                    image.src = slide.url;

                    image.onload = async () => {
                        const rotatedBlob = await this.rotateImageToBlob(image, slide.rotation);

                        const file = this.blob2File(rotatedBlob, slide.name);

                        const updatedSlide = await this.replaceSlideFile(slide, file);

                        const index = this.reversedSlides.findIndex(x => x.id === slide.id);

                        this.reversedSlides.splice(index, 1, {
                            ...slide,
                            // url: 'http://via.placeholder.com/1280x720',
                            url: updatedSlide.url,
                            rotation: 0,
                            loading: false,
                        });
                        resolve();
                    };

                    image.onerror = () => {
                        console.error('image rotate error');
                        reject(error);
                    };
                } catch (error) {
                    reject(error);
                }
            });
        },
        blob2File(blob, fileName) {
            return new File([blob], fileName, {
                lastModified: new Date(),
                type: blob.type,
            });
        },
        async replaceSlideFile(slide, file) {
            try {
                if (!slide) throw new Error('Slide to replace not found');
                if (slide.mediaType === 1) {
                    console.log('TODO: upload video edits...');
                    alert('saving video edits is not wired up yet.');
                    // this.apiService.tributeVideo.getUploadUrl()
                } else {
                    let { data: SAS_INFO } = await this.apiService.tributePhoto.getUploadUrl(slide.eventId, file);

                    // TODO: add cancel token
                    await this.apiService.blob.upload(SAS_INFO.sas, file, {
                        // onUploadProgress: this.createProgressHandler(file),
                    });

                    const replacement = await this.apiService.tributePhoto.replacePhoto(
                        slide.id,
                        SAS_INFO.fileName,
                        true,
                    );
                    console.log('Updating lastSyncedChange');
                    this.$store.dispatch('tributeVideo/updateTributeVideo', {
                        // Set lastSyncedChange, in case we need to delay render
                        lastSyncedChange: moment().toISOString(),
                    });
                    let updatedSlide = { ...slide, url: replacement?.data?.url, rotation: 0, loading: false };

                    return updatedSlide;
                }
            } catch (error) {
                console.log(error, 'error replacing photo');
            }
        },
        /**
         * Rotates an image and returns the rotated copy as a Blob.
         * @param {HTMLImageElement} image - The source image to rotate.
         * @param {number} angle - The angle to rotate the image in degrees (clockwise).
         * @param {string} mimeType - The MIME type of the output image (e.g., 'image/png', 'image/jpeg').
         * @param {number} quality - The quality of the output image (for lossy formats like JPEG, range 0-1).
         * @returns {Promise<Blob>} A Promise that resolves with the rotated image as a Blob.
         */
        rotateImageToBlob(image, angle, mimeType = 'image/png', quality = 1) {
            return new Promise((resolve, reject) => {
                try {
                    // Create a canvas to draw the rotated image
                    const canvas = document.createElement('canvas');
                    const ctx = canvas.getContext('2d');

                    // Calculate the dimensions of the rotated image
                    canvas.width = angle % 180 === 0 ? image.width : image.height;
                    canvas.height = angle % 180 === 0 ? image.height : image.width;

                    //Center the canvas
                    ctx.translate(canvas.width / 2, canvas.height / 2);

                    // Convert the angle to radians
                    const radians = (angle * Math.PI) / 180;

                    // Rotate the canvas
                    ctx.rotate(radians);

                    // Draw the image onto the rotated canvas
                    ctx.drawImage(image, -image.width / 2, -image.height / 2);

                    canvas.toBlob(
                        blob => {
                            if (blob) {
                                resolve(blob);
                            } else {
                                reject(new Error('Canvas toBlob failed'));
                            }
                        },
                        mimeType,
                        quality,
                    );
                } catch (error) {
                    reject(error);
                }
            });
        },
        handleSocketRefresh: debounce(async function () {
            this.pageNumber = 0;
            this.pageSize = Math.max(24, this.reversedSlides.length + 1);

            let data = {
                reversed: true,
                pageNumber: this.pageNumber,
                pageSize: this.pageSize,
            };

            console.log(data, 'refresh photo data');

            this.replaceCurrentPhotos(data);
            this.$emit('refresh-preview');
        }, 500),

        focusImage(index) {
            this.$nextTick(() => {
                const slide = this.$el.querySelectorAll('.slide')[index];
                if (slide) {
                    slide.scrollIntoView({ behavior: 'smooth', inline: 'center' });
                }
            });
        },

        closeDeleteModal() {
            this.showDeleteModal = false;
        },
        confirmDelete() {
            this.closeDeleteModal();

            if (this.selectedSlides.length === 0) return;

            this.deleteSelectedSlides();
        },
        // getCurrentPhotos() {
        //     return this.slides.map(slide => ({
        //         id: slide.id,
        //         url: slide.url,
        //         rotation: slide.rotation,
        //     }));
        // },
        // getCurrentPhotoOrder() {
        //     return this.slides.map(slide => slide.id);
        // },
    },
    sockets: {
        async NotifyUpload(data) {
            if (data.id != this.eventId) return;

            console.log('upload heard', data);

            if (this.pendingUploads.length > 0) return;

            if (this.pendingReorder || this.pendingDelete || this.pendingRotate) return;

            this.handleSocketRefresh();
        },
    },
};
</script>

<style lang="scss" scoped>
.manage-photos-container:focus {
    outline: none;
}

.sortable-selected {
    .slide-image {
        border: 4px solid #ea580c;
    }
}

.sortable-drag {
    .slide-image {
        border-radius: 6px;
        transform: rotate(-3deg) !important;
        border: 4px solid #ea580c;
    }
}

.sortable-ghost {
    .slide-image {
        border: 2px solid #ea580c;
    }
}

.manage-photos-container {
    display: flex;
    flex-direction: column;
}

.slides-container {
    display: flex;
    flex-wrap: nowrap;
    gap: 8px;
    overflow-x: auto;
    overflow-y: hidden;
    transition: height 0.8s;
    height: 100%;
}

.slides-container.expanded {
    flex-wrap: wrap;
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 100%;
    align-content: flex-start;
}

.slide,
.pending-slide {
    height: 100%;
    max-height: 242px;
    min-height: 100px;
    aspect-ratio: 1;
    border-radius: 8px;
    position: relative;
    // transition: transform 0.2s, border 0.2s;
    flex-shrink: 0;
    display: inline-block;

    .slide-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 8px;
        z-index: -1;
        background-color: #f1f4f7;
    }
}
.pending-slide {
    img {
        opacity: 0.4;
    }

    .progress-indicator {
        width: 93%;
        display: inline-block;
        height: 5px;
        bottom: 10px;
        position: absolute;
        left: 0;
        right: 0;
        background: #ffedd5;
        margin: auto;
        border-radius: 10rem;
    }

    .progress-slider {
        height: 100%;
        background: #f97316;
        border-radius: 10rem;
        transition: width 200ms ease-in-out;
    }
}

// .slide.dragging {
//     transform: rotate(-3deg) !important;
//     border: 4px solid #ea580c;
// }

// .slide.selected .slide-image {
//     border: 4px solid #ea580c;
// }
.slide-preview-inner,
.slide-order {
    min-width: 42px;
    height: 32px;
    background: white;
    border: 1px solid #d1d5db;
    box-shadow: 0px 1px 2px 0px #0000000d;
    color: #6b7280;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 2.5%;
    padding: 0 12px;
}
.slide-preview {
    position: absolute;
    z-index: 10;
    bottom: 8px;
    right: 8px;
    display: none;
    transition: opacity 250ms ease-in-out;
    transition-delay: 200ms;
    opacity: 0;
}

.slide:hover {
    .slide-preview {
        display: flex;
        opacity: 1;
    }
}
.slide-order {
    z-index: 10;
    top: 8px;
    left: 8px;
    position: absolute;

    .duration {
        margin-left: 18px;
        text-wrap: nowrap;
        &:before {
            content: '';
            background: lighten($medium-gray, 16%);
            width: 2px;
            height: 10px;
            bottom: 0;
            position: absolute;
            top: 0;
            margin: auto;
            border-radius: 4px;
            transform: translateX(-10px);
        }
    }
}

.selected-indicator {
    position: absolute;
    top: 8px;
    right: 8px;
    width: 24px;
    height: 24px;
    background: #ea580c;
    border: 1.5px solid #ea580c;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ghost-hidden {
    display: none !important;
}

.ghost-slide {
    // width: 242px;
    // height: 242px;
    height: 100%;
    max-height: 242px;
    min-height: 100px;
    aspect-ratio: 1;
    border: 1px dashed #d1d5db;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    flex-shrink: 0;
}

.ghost-icon-container {
    width: 52px;
    height: 38px;
    background: #fff7ed;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
}

.ghost-icon {
    width: 20px;
    height: 20px;
}
::v-deep {
    .v-carousel {
        width: fit-content;
    }
    .v-dialog.v-dialog--active:has(.preview-modal) {
        width: auto;
        box-shadow: none;
    }
    .v-window__next,
    .v-window__prev {
        background: none;
    }
    .carousel-btn {
        .v-btn__content {
            color: $primary-grey;
            font-size: 1.4rem;
            font-weight: bold;
        }
        &.v-btn.v-size--default {
            border: 2px solid $primary-grey;
            padding: 0;
            min-width: 36px;
            width: 48px;
        }
    }

    .v-skeleton-loader__image {
        height: 100%;
    }
}

.preview-modal {
    .slide-toolbar {
        display: flex;
        gap: 10px;
        justify-content: end;
        background: $light-gray;

        .v-btn.v-size--default {
            border: 2px solid lighten($primary-grey, 10%) !important;
            padding: 0;
            min-width: 36px;
            width: 48px;
        }
    }
}
.carousel-content {
    display: flex;
    justify-content: center;
    height: 100%;
    &::v-deep {
        .v-responsive__content {
            width: fit-content;
        }
    }
}
.modal-close-btn {
    position: fixed;
    top: 10px;
    right: 10px;
    margin: auto;
    width: auto;
    &.v-btn:not(.v-btn--round).v-size--default {
        padding: 0;
        min-width: 36px;
        width: 48px;
    }
}
.v-carousel .edit-button {
    position: absolute;
    left: auto;
    right: 10px;
    top: 10px;
    margin: auto;
    z-index: 99;
    width: fit-content;

    .v-btn:not(.v-btn--round).v-size--default {
        padding: 0;
        min-width: 36px;
        width: 48px;
    }
}
.slide-delete-modal {
    &::v-deep {
        .modal-header {
            justify-content: start;
            margin-bottom: 0;
            .icon {
                height: 32px;
                margin-right: 4px;
            }
        }
        .modal-header,
        .modal-content,
        .modal-footer {
            border: none;
        }
    }
}
.image-error-div {
    min-height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
</style>
