<template>
  <div v-if="isOpen" class="modal-container">
    <div class="modal-overlay" @click="closeModal"></div>
    <div class="modal-content">
      <div class="modal-banner">
        <div class="banner-text"> {{ templates[this.selectedTemplateIndex].title }} </div>
        <div class="close-button" @click="closeModal">
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5 15L15 5M5 5L15 15" stroke="#6B7280" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
        </div>
      </div>

      <div class="modal-body">
        <div class="preview-container">
          <div class="video-frame">
            <CreatomateSmallPreview :template="this.templates[this.selectedTemplateIndex].cosmosId"
              :FirstName="mods['FirstName'].value" :LastName="mods['LastName'].value"
              :BirthDate="mods['BirthDate'].value" :DeathDate="mods['DeathDate'].value" :photo="this.$props.mainPhoto" :customFont="tempFont" />
          </div>
        </div>

        <div class="mods-form">
          <label>Font</label>
          <google-fonts-drop-down v-show="isOpen" v-model="tempFont" class="font-drop-down"
            @font-changed="handleFontChange" ref="fontDropdown"></google-fonts-drop-down>

          <div class="form-group-row">
            <div class="form-group">
              <label>First Name</label>
              <input v-model="mods['FirstName'].value" maxlength="100" type="text" class="text-input" />
            </div>

            <div class="form-group">
              <label>Last Name</label>
              <input v-model="mods['LastName'].value" maxlength="100" type="text" class="text-input" />
            </div>
          </div>

          <div class="form-group">
            <label>Birth Year</label>
            <input v-model="mods['BirthDate'].value" maxlength="4" type="text" class="text-input" />
          </div>

          <div class="form-group">
            <label>Death Year</label>
            <input v-model="mods['DeathDate'].value" maxlength="4" type="text" class="text-input" />
          </div>
        </div>
      </div>

      <div class="modal-footer">
        <v-btn class="save-button prev-button" :style="{ opacity: hasChanges ? 1 : 0.9 }"
          @click="$emit('change-template', 'prev')">
          <v-icon left> mdi-chevron-left </v-icon>
          Prev
        </v-btn>

        <v-btn class="save-button use-template-button" :style="{ opacity: hasChanges ? 1 : 0.9 }"
          @click="$emit('save')">
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.0002 3.33398V16.6673M16.6668 10.0007L3.3335 10.0007" stroke="black" stroke-width="2"
              stroke-linecap="round" stroke-linejoin="round" />
          </svg>
          Use This Template
        </v-btn>

        <v-btn class="save-button next-button" :style="{ opacity: hasChanges ? 1 : 0.9 }"
          @click="$emit('change-template', 'next')">
          Next
          <v-icon right> mdi-chevron-right </v-icon>
        </v-btn>
      </div>

    </div>
  </div>
</template>

<script>
import CreatomateSmallPreview from '@/components/Tribute/Themes/LivePreview.vue';
import GoogleFontsDropDown from '@/components/Tribute/Themes/GoogleFontsDropdown.vue';
import { mapActions } from 'vuex';
import TributeVideoService from '@/services/tributeVideo.service';

export default {
  name: 'PreviewThemeModal',
  components: {
    CreatomateSmallPreview,
    GoogleFontsDropDown,
  },
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    tributeId: {
      type: Number,
      required: true,
    },
    currentFont: {
      type: Object,
      required: true,
    },
    currentModifications: {
      type: Array,
      required: true,
    },
    mainPhoto: {
      type: String,
      required: false,
    },
    templates: {
      type: Array,
      required: true,
    },
    selectedTemplateIndex: {
      type: Number,
      required: true,
    },
  },
  async mounted() {
    const { __raw } = await this.$auth.getIdTokenClaims();
    this.token = __raw;
    this.api = TributeVideoService(this.token);
    if (this.isOpen) {
      this.$nextTick(() => {
        this.ensureFontsLoaded(() => {
          if (this.$refs.fontDropdown && this.$refs.fontDropdown.setFont) {
            this.$refs.fontDropdown.setFont(this.tempFont.family);
          }
        });
      });
    }
  },
  watch: {
    currentModifications: {
      immediate: true,
      handler(newVal) {
        if (newVal.length > 0) {
          this.initializeTempModifications();
        }
      },
    },
    isOpen(newVal) {
      if (newVal) {
        this.$nextTick(() => {
          this.ensureFontsLoaded(() => {
            if (this.$refs.fontDropdown && this.$refs.fontDropdown.setFont) {
              this.$refs.fontDropdown.setFont(this.currentFont.family);
            }
          });
        });
      }
    },
  },

  data() {
    return {
      tempFont: this.currentFont,
      mods: null,
      hasChanges: false,
      api: null,
    };
  },
  methods: {
    ...mapActions(['showSnackbar']),
    ...mapActions('tributeVideo', ['updateTributeVideo', 'updateTributeVideoSelectedTemplates']),
    initializeTempModifications() {
      const expectedNames = ['FirstName', 'LastName', 'BirthDate', 'DeathDate'];
      this.mods = expectedNames.reduce((acc, name) => {
        const mod = this.currentModifications.find(m => m.name === name);
        acc[name] = mod ? { key: mod.key, value: mod.value } : { key: '', value: '' };
        return acc;
      }, {});
    },
    closeModal() {
      this.$emit('close');
    },
    handleFontChange(font) {
      this.tempFont = font;
    },
    ensureFontsLoaded(callback) {
      const dropdown = this.$refs.fontDropdown;
      if (dropdown && dropdown.fonts.length > 0) {
        callback();
      } else {
        const interval = setInterval(() => {
          if (dropdown && dropdown.fonts.length > 0) {
            clearInterval(interval);
            callback();
          }
        }, 50);
      }
    },
    async refreshSmallPreview() {
      var resp = await this.api.generatePreviewJson();

      if (resp.data.creatomateJson) {
        const creatomateJson = JSON.parse(resp.data.creatomateJson);

        if (!creatomateJson.source) throw new Error('Error parsing preview source');

        this.source = JSON.stringify(creatomateJson.source);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-container {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  border: 1px solid #D1D5DB;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9998;
}

.modal-content {
  position: relative;
  width: auto;
  min-width: 50vw;
  max-width: 98vw;
  height: 99vh;
  background: white;
  z-index: 9999;
}

.modal-banner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  max-height: 5vh;
  background: white;
  border-bottom: 1px solid #D1D5DB;
}

.preview-container {
  margin-top: -20px;
padding: 20px;
display: flex;
justify-content: center;
align-items: center;
flex-grow: 1;
max-height: 60vh;

@media screen and (max-width: 768px) {
  margin-top: 0px;
  max-height: 70vh;
}

@media screen and (max-width: 480px) {
  padding: 5px;
  max-height: 80vh; 
}
}

.video-frame {
max-width: 700px;
width: 100%;
aspect-ratio: 16 / 9;
background-color: #ffffff;
padding: 12px;
border-radius: 8px;
box-shadow: 0px 7.47px 7.47px -3.74px rgba(0, 0, 0, 0.04);
box-shadow: 0px 14.95px 18.69px -3.74px rgba(0, 0, 0, 0.1);

@media screen and (max-width: 768px) {
  max-width: 90%;
}

@media screen and (max-width: 480px) {
  max-width: 100%;
}
}

.mods-form {
  margin-top: -20px;
  max-height: 40vh;

  @media screen and (max-width: 1200px) {
    margin-top: 0px;
}
}

.banner-text {
  font-family: 'Inter', sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #374151;
}

.close-button {
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #D1D5DB;
  border-radius: 6px;
  cursor: pointer;
  box-shadow: 0px 1px 2px 0px #0000000D;
}

.modal-body {
  flex: 1;
  padding: 0px 16px;
  background: #F9FAFB;
  overflow-y: auto;
}

label {
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #374151;
}

.form-group-row {
  display: flex;
  justify-content: space-between;
  gap: 16px;
}

.form-group {
  flex: 1;
}

.text-input {
width: 100%;
height: 38px;
padding: 8px;
border: 1px solid #d1d5db;
border-radius: 6px;
box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
background-color: #ffffff;

@media screen and (max-width: 768px) {
  height: 48px;
}

@media screen and (max-width: 480px) {
  height: 58px;
}
}

.modal-footer {
display: flex;
justify-content: space-between;
align-items: center;
height: 8vh;
background-color: #ffffff;
padding: 0 10px;
gap: 5px;
width: 100%;

@media screen and (max-width: 768px) {
  height: 12vh;
}
}

.save-button {
  height: 38px;
  border-radius: 6px;
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
  cursor: pointer;
  text-transform: none;
  letter-spacing: normal;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  flex: 1;
  min-width: 0;
}
.prev-button,
.next-button {
flex: 0 0 20%; 
  background-color: #ffffff;
color: black;
border: 1px solid #d1d5db;
}

.use-template-button {
flex: 0 0 50%; 
background-color: #ffffff;
color: black;
border: 1px solid #d1d5db;
}

.next-button {
background-color: $primary-orange !important;
color: white !important;
}

.save-button[disabled] {
cursor: not-allowed;
opacity: 0.5;
}
</style>

