<template>
    <div style="z-index: 11">
        <v-menu offset-y v-model="menuOpen" max-width="512px" :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
                <button class="share-button" v-bind="attrs" v-on="on" :disabled="disabled">
                    <span class="hidden-lg-and-up">Share</span>
                    <span class="hidden-md-and-down">Share Link for Family</span>
                    <span class="divider"></span>
                    <span class="chevron">&#9662;</span>
                </button>
            </template>
            <v-card>
                <v-tabs v-model="tabIndex" color="#ee5207" grow>
                    <v-tab v-for="(tab, index) in shareTabs" :ripple="false" :key="index">{{ tab.tabTitle }}</v-tab>
                </v-tabs>

                <v-tabs-items v-model="tabIndex">
                    <v-tab-item v-for="(tab, index) in shareTabs" :value="index">
                        <v-list class="sharing-menu">
                            <v-list-item :key="tab.link">
                                <v-list-item-title class="font-weight-bold">{{ tab.linkTitle }}</v-list-item-title>
                                <v-list-item-subtitle style="white-space: normal">
                                    {{ tab.linkSubtitle }}
                                </v-list-item-subtitle>
                                <v-list-item-subtitle>
                                    <v-text-field
                                        outlined
                                        dense
                                        class="has-addon__right"
                                        prepend-inner-icon="mdi-account-group-outline"
                                        v-model="tab.link"
                                        readonly
                                    >
                                        <v-btn
                                            :disabled="tab.link === '...'"
                                            depressed
                                            slot="append-outer"
                                            @click="copyToClipboard(tab.linkType, tab.link)"
                                        >
                                            <v-icon left dark class="mr-1"> mdi-clipboard-outline </v-icon>
                                            Copy</v-btn
                                        >
                                    </v-text-field>
                                </v-list-item-subtitle>
                            </v-list-item>
                            <hr class="m-2" />

                            <v-list-item>
                                <v-list-item-title class="font-weight-bold">Send Email or SMS</v-list-item-title>
                                <v-list-item-subtitle> Send this link via email or sms </v-list-item-subtitle>
                                <EmailSmsLink
                                    v-if="menuOpen"
                                    @submit="data => tab.shareCallback(data)"
                                    :link="tab.link"
                                >
                                    <template #append-outer="{ submit, listLength }">
                                        <v-btn
                                            style="background-color: rgba(251, 140, 0, 0.15); color: rgb(251, 140, 0)"
                                            @click="submit"
                                            depressed
                                            :disabled="isSendLinkDisabled(listLength, tab.linkType)"
                                            >Send</v-btn
                                        >
                                    </template>
                                </EmailSmsLink>
                            </v-list-item>
                        </v-list>
                    </v-tab-item>
                </v-tabs-items>
            </v-card>
        </v-menu>
    </div>
</template>
<script>
import { mapActions } from 'vuex';
import EmailSmsLink from '@/components/ui/EmailSmsLink.vue';
import TributeVideoService from '@/services/tributeVideo.service';

export default {
    data() {
        return {
            menuOpen: false,
            tabIndex: 0,
            tributeApi: null,
            token: null,
        };
    },
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    components: { EmailSmsLink },
    computed: {
        isSendLinkDisabled() {
            return (listLength, linkType) => {
                return listLength === 0 || (linkType === 'Completed video' && this.completedVideoLink === '...');
            };
        },
        shareTabs() {
            return [
                {
                    tabTitle: 'Invite Family',
                    linkTitle: 'Invite Family to Upload Media',
                    linkSubtitle: ' Allow family members to contribute photos and videos for the tribute.',
                    link: this.familyShareLink,
                    linkType: 'Family',
                    shareCallback: this.handleInviteSubmit,
                },
                {
                    tabTitle: 'Invite Contributors',
                    linkTitle: 'Contributors',
                    linkSubtitle: 'Only allows photo uploads. Recommended for extended family and friends.',
                    link: this.contributorLink,
                    linkType: 'Contributor',
                    shareCallback: this.handleInviteSubmit,
                },
                {
                    tabTitle: 'TributeVideo',
                    linkTitle: 'Share Completed Tribute Video',
                    linkSubtitle: 'Allow anyone with the link to view the tribute video.',
                    link: this.completedVideoLink,
                    linkType: 'Completed video',
                    shareCallback: this.handleTributeVideoShare,
                },
            ];
        },
        familyShareLink() {
            return this.$store.state.tributeVideo.familyLink || '...';
        },
        contributorLink() {
            return this.$store.state.tributeVideo.deeplink || '...';
        },
        completedVideoLink() {
            // TODO: Figure out where the completed video link lives
            if (this.tributeRender?.standardRender?.url) {
                return `${process.env.VUE_APP_VIDEO}/video/${this.$route.params.slug}/tribute-video`;
            }

            return '...';
        },
        tributeVideoId() {
            return this.$store.state.tributeVideo.id;
        },
    },
    inject: ['tributeRender'],
    methods: {
        ...mapActions(['showSnackbar']),
        copyToClipboard(type, link) {
            // TODO: browser support for this is really good, but need to double check if a fallback is needed
            navigator.clipboard.writeText(link);
            this.showSnackbar({
                message: `${type} link copied.`,
            });
            this.sharingMenuOpen = false;
        },
        handleInvalidEmail(email) {
            this.showSnackbar({ message: `${email} is not a valid email`, color: 'error' });
        },
        async handleInviteSubmit(contacts) {
            let data = {
                emailList: contacts.emailList.join(','),
                phoneList: contacts.phoneList.join(','),
                url: contacts.url,
            };

            try {
                await this.tributeApi.invite(this.tributeVideoId, data);
                this.showSnackbar({ message: 'Invitations sent' });
                this.menuOpen = false;
            } catch (error) {
                console.log(error, 'error during invite submit');
                if (error.response.status === 401) {
                    this.showSnackbar({ message: 'Unauthorized access', color: 'error' });
                } else {
                    this.showSnackbar({ message: 'Error sending invitations', color: 'error' });
                }
            }
        },
        async handleTributeVideoShare(contacts) {
            let data = {
                emailList: contacts.emailList.join(','),
                phoneList: contacts.phoneList.join(','),
                url: contacts.url,
            };

            try {
                await this.tributeApi.shareFinalVideo(this.tributeVideoId, data);
                this.showSnackbar({ message: 'Invitations sent' });
                this.menuOpen = false;
            } catch (error) {
                console.log(error, 'error during invite submit');
                if (error.response.status === 401) {
                    this.showSnackbar({ message: 'Unauthorized access', color: 'error' });
                } else {
                    this.showSnackbar({ message: 'Error sending invitations', color: 'error' });
                }
            }
        },
        async setAuthToken() {
            const response = await this.$auth.getIdTokenClaims();
            this.token = response.__raw;
        },
    },
    async mounted() {
        await this.setAuthToken();
        this.tributeApi = TributeVideoService(this.token);
    },
};
</script>
<style lang="scss" scoped>
.share-button {
    padding: 0.5rem 1rem;
    font-weight: 500;
    background-color: $primary-orange;
    color: white;
    border-radius: 0.375rem;
    display: flex;
    align-items: center;
    gap: 8px;

    &:disabled {
        cursor: not-allowed;
        opacity: 0.6;
    }
}

.sharing-menu {
    padding: 1.5rem 0.5rem;
    display: flex;
    flex-direction: column;

    .v-list-item {
        display: inline-block;
        padding: 0.5rem;
    }

    .v-list-item__title {
        font-weight: 500;
    }

    .v-list-item__title,
    .v-list-item__subtitle {
        margin: 4px 0;
        line-height: 1.3;
    }

    ::v-deep {
        .v-input.has-addon__right {
            .v-input__slot {
                margin: 0;
            }

            .v-input__control {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
                fieldset {
                    border-color: #ccc;
                    border-right: transparent;
                }
            }

            .v-input__append-outer {
                margin: 0 !important;

                button {
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;
                    border: 1px solid #ccc;
                    height: 40px;
                }
            }
        }
    }
}

.share-button .divider {
    width: 1px;
    height: 20px;
    background-color: white;
    margin-left: 8px;
}
</style>
