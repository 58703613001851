<template>
    <div ref="slideUpload">
        <slot name="activator" :on-click="openFileSelection">
            <label for="image" class="upload-button" @click="openFileSelection">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M2.667 10.667v.666c0 1.105.896 2 2 2h6.667c1.105 0 2-.895 2-2v-.666M10.667 5.333L8 2.667M8 2.667L5.333 5.333M8 2.667v8"
                        stroke="#6B7280"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                </svg>
                <span>Upload</span>
            </label>
        </slot>

        <input type="file" id="uppy-file-input" style="display: none" />
    </div>
</template>
<script>
import Uppy from '@uppy/core';
import FileInput from '@uppy/file-input';
import DropTarget from '@uppy/drop-target';

import '@uppy/drop-target/dist/style.css';
import { assign, each, omit } from 'lodash';
import initApiServices from '@/services/ApiInitializer';
import { mapActions } from 'vuex';

export default {
    data() {
        return {
            uppy: null,
            apiService: null,
            tempFiles: [],
            uploadedBytes: 0,
            totalBytes: 0,
            anonPages: ['TributeUploadPage', 'TributeFamilyPage'],
        };
    },
    props: {
        maxFiles: {
            type: Number,
            default: 999,
        },
        videoAllowed: {
            type: Boolean,
            default: true,
        },
        batchSize: {
            type: Number,
            default: 10,
        },
        dropTarget: {
            type: [String, null],
            default() {
                return null;
            },
        },
    },
    watch: {
        tempFiles: {
            handler(newVal) {
                this.$emit('change', newVal);
            },
            deep: true,
        },
    },
    methods: {
        ...mapActions(['showSnackbar']),
        initUppy() {
            let allowedFileTypes = this.getAllowedFilesTypes();

            this.uppy = new Uppy({
                restrictions: {
                    minNumberOfFiles: 1,
                    maxNumberOfFiles: this.maxFiles,
                    allowedFileTypes,
                },
            })
                .use(FileInput, {
                    target: '#uppy-file-input',
                    pretty: false,
                })
                .on('file-added', async file => {
                    this.tempFiles = [...this.tempFiles, file];
                    this.$emit('filesAdded', file);
                })
                .on('file-removed', async file => {
                    this.tempFiles = this.uppy.getFiles();
                })
                .on('restriction-failed', (file, error) => {
                    this.showSnackbar({ message: error.message, color: 'error' });
                });
            if (this.$props.dropTarget) {
                let dropTarget = document.querySelector(this.$props.dropTarget);
                // If element isn't found but a droptarget was specified... should we fallback to body?
                if (!(dropTarget instanceof HTMLElement)) {
                    dropTarget = document.body;
                }
                this.uppy.use(DropTarget, {
                    target: dropTarget,
                });
            }
        },
        openFileSelection() {
            const input = this.uppy.getPlugin('FileInput');
            if (input) {
                input.handleClick();
            }
        },
        reset() {
            this.uppy.cancelAll();
        },
        async initUpload(eventId, replaceMainPhoto = false) {
            try {
                if (!eventId || eventId <= 0) {
                    throw new Error('Invalid eventId');
                }

                const files = this.tempFiles;

                const allowedRemainingUploads = await this.apiService.tributePhoto.getRemainingAllowedUploads(eventId);
                if (allowedRemainingUploads?.data?.remaining < files.length) {
                    throw new Error(`Cannot exceed max upload limit`);
                }

                const batchedFiles = this.chunkArray(files, this.batchSize);

                if (files.length === 0) {
                    throw new Error('No files found');
                }

                if (replaceMainPhoto && files.length !== 1) {
                    throw new Error('Only one file can be uploaded when replacing the main photo.');
                }

                const cancelTokenSource = this.axios.CancelToken.source();
                this.loading = true;
                this.uploadedBytes = 0;
                this.totalBytes = files.reduce((acc, file) => acc + file.size, 0);

                let uploadResponse;

                if (replaceMainPhoto) {
                    uploadResponse = await this.handleReplaceMainPhoto(eventId, files[0], cancelTokenSource.token);
                } else {
                    let successfulUploads = [];

                    for (const batch of batchedFiles) {
                        uploadResponse = await this.processFileBatch(eventId, batch, cancelTokenSource.token);
                        successfulUploads = successfulUploads.concat(uploadResponse);
                        this.$emit('successful-uploads', uploadResponse);
                    }

                    uploadResponse = successfulUploads;
                }
                return uploadResponse;
            } catch (error) {
                this.showSnackbar({ message: error.message, color: 'error' });
                console.error('Error uploading files', error);
            } finally {
                await this.reset();
                this.loading = false;
            }
        },
        chunkArray(arr, chunkSize) {
            let results = [];

            for (let i = 0; i < arr.length; i += chunkSize) {
                const chunk = arr.slice(i, i + chunkSize);
                results.push(chunk);
            }

            return results;
        },
        async handleReplaceMainPhoto(eventId, file, cancelToken) {
            try {
                const fileData = await this.processSingleFileAzureUpload(eventId, file, cancelToken);

                const response = await this.apiService.tributePhoto.createPhotoBatch(eventId, [fileData], true, {
                    cancelToken,
                });

                this.uploadedBytes += file.size;

                return response;
            } catch (error) {
                console.log(error, 'error');
            }
        },
        getAllowedFilesTypes() {
            let allowedFileTypes = [];

            if (!this.videoAllowed) {
                allowedFileTypes = ['.jpg', '.jpeg', '.png', '.svg', '.heic'];
            } else {
                allowedFileTypes = ['.jpg', '.jpeg', '.png', '.svg', '.mp4', '.mov', '.heic'];
            }

            //TEMP TESTING
            allowedFileTypes.push('.arw');

            return allowedFileTypes;
        },
        async processFileBatch(eventId, files, cancelToken) {
            try {
                const uploadResults = await Promise.allSettled(
                    files.map(file => this.processSingleFileAzureUpload(eventId, file, cancelToken)),
                );

                const enrichedUploadResults = uploadResults.map((result, index) => ({
                    ...result,
                    uppyId: files[index].id, // Assuming each file object has an 'uppyId' property
                }));

                const successfulUploads = uploadResults
                    .filter(result => result.status === 'fulfilled')
                    .map(result => result.value);

                const dbResponse = await this.uploadDbPhotoBatch(eventId, successfulUploads, cancelToken);

                const response = enrichedUploadResults
                    .filter(result => result.status === 'fulfilled')
                    .map(result => ({
                        dbData: dbResponse.find(dbItem => dbItem.url === result.value.url),
                        uppyId: result.uppyId,
                    }));

                return response;
            } catch (error) {
                console.error('Error during batch file process', error);
            }
        },
        async processSingleFileAzureUpload(eventId, file, cancelToken) {
            try {
                const {
                    data: { sas, fileName },
                } = await this.getUploadUrl(eventId, file);

                await this.uploadToAzureStorage(sas, file, cancelToken);

                const fileData = await this.prepareFileData(file, sas, fileName);

                this.uploadedBytes += file.size;

                return fileData;
            } catch (error) {
                this.handleFileUploadError(file, error);
                throw error;
            }
        },
        getFileNameParts(fileName) {
            const [base, ...extParts] = fileName.split('.');
            const extension = extParts.length > 0 ? extParts.join('.') : '';
            return { base, extension };
        },

        handleFileUploadError(file, error) {
            if (error.message === 'Upload canceled by user.') {
                this.showSnackbar({ message: 'Upload cancelled', color: 'error' });
            } else {
                console.error(`Error uploading ${file.name}:`, error);
                this.showSnackbar({ message: `Error uploading ${file.name}`, color: 'error' });
            }
        },
        async prepareFileData(file, sas, fileName) {
            let uploadSource = 0;
            let uploadUserName = 'Unknown';
            let uploadUserRelationShip = 'Funeral Staff';

            if (this.anonPages.includes(this.$route.name)) {
                uploadSource = 2;
                uploadUserName = 'Unknown';
                uploadUserRelationShip = 'Unknown';
            } else if (this.$auth.user.name) {
                uploadUserName = this.$auth.user.name;
            }

            let fileData = {
                duration: 0,
                mediaType: 0,
                uploadSource: uploadSource,
                uploadUserName: uploadUserName,
                uploadUserRelationship: uploadUserRelationShip,
                url: sas.split('?sv=')[0],
                uniqueName: fileName,
                name: file.name,
            };

            let isVideo = this.isVideoFile(file.extension);

            if (isVideo) {
                fileData.duration = await this.getVideoDuration(file);
                fileData.mediaType = 1;
            }

            return fileData;
        },
        async uploadDbPhotoBatch(eventId, photoBatchData, cancelToken) {
            try {
                const config = {
                    cancelToken: cancelToken,
                };
                var resp = await this.apiService.tributePhoto.createPhotoBatch(eventId, photoBatchData, false, config);
                if (resp.data) {
                    return resp.data;
                }
            } catch (error) {
                console.log(error, 'error');
            }
        },
        async getUploadUrl(eventId, file) {
            try {
                return await this.apiService.tributePhoto.getUploadUrl(eventId, file);
            } catch (error) {
                console.log(error, 'error');
            }
        },
        async uploadToAzureStorage(sas, file, cancelToken) {
            await this.apiService.blob.upload(sas, file, {
                onUploadProgress: this.createProgressHandler(file),
                cancelToken: cancelToken,
            });
        },
        createProgressHandler(file) {
            return progressEvent => {
                const progressPayload = {
                    uploader: this,
                    bytesUploaded: progressEvent.loaded,
                    bytesTotal: progressEvent.total,
                    percentage: (progressEvent.loaded / progressEvent.total) * 100,
                    uploadComplete: progressEvent.total < progressEvent.loaded ? false : true,
                    uploadStarted: progressEvent.total > 0 ? true : false,
                };
                this.uppy.emit('upload-progress', file, progressPayload);
                // update the file's internal progress
                each(this.tempFiles, tmpfile => {
                    if (tmpfile.id === file.id) {
                        assign(tmpfile.progress, omit(progressPayload, ['uploader']));
                    }
                });
                const currentBytes = this.uploadedBytes + progressEvent.loaded;
                const percent = Math.ceil((currentBytes / this.totalBytes) * 100);

                this.$emit('progress', percent);
            };
        },
        isVideoFile(fileExtension) {
            const videoFileTypes = ['mp4', 'mov', 'avi', 'mkv', 'wmv', 'flv', 'webm'];

            // Check if the file extension is included in the videoFileTypes array
            return videoFileTypes.includes(fileExtension.toLowerCase());
        },
        getVideoDuration(file) {
            return new Promise((resolve, reject) => {
                const video = document.createElement('video');
                video.preload = 'metadata';

                video.onloadedmetadata = () => {
                    window.URL.revokeObjectURL(video.src);
                    const duration = video.duration;
                    resolve(Math.round(duration));
                };

                video.onerror = () => {
                    reject(new Error('Failed to retrieve video duration.'));
                };

                video.src = URL.createObjectURL(file.data);
            });
        },
        async setAuthToken() {
            const response = await this.$auth.getIdTokenClaims();
            this.token = response.__raw;
        },
    },
    async mounted() {
        this.initUppy();
        await this.setAuthToken();
        this.apiService = initApiServices(this.token);
        // If a droptarget was specified then give that target a class for styling the drop area
        if (this.$props.dropTarget) {
            const dropTarget = document.querySelector(this.$props.dropTarget);
            if (dropTarget && !dropTarget.classList.contains('drop-target')) {
                dropTarget.classList.add('drop-target');
            }
        }
    },
};
</script>
<style lang="scss" scoped>
.upload-button {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #d1d5db;
    background-color: #fff;
    color: #374151;
    cursor: pointer;
    box-shadow: 0px 1px 2px 0px #0000000d;
}
</style>
